const carouselData = [
  {
    id: 1,
    title: "Bhuta Shuddh",
    location: "Location: Solapur",
    date: "15th Mar 2025",
    time: "06:00 AM - 07:30 AM",
    registrationLink: "/bhuta-shuddhi",
    startDate: "2025-03-15",
    endDate: "2025-03-15"
  },
  {
    id: 2,
    title: "Shanmukhi Mudra",
    location: "Location: Solapur",
    date: "15th Mar 2025",
    time: "07:45 AM - 09:00 AM",
    registrationLink: "/shanmukhi-mudra",
    startDate: "2025-03-15",
    endDate: "2025-03-15"
  }
];

export default carouselData;
// src/data.js
import img1 from '../../assets/Angamardhana_1.jpg';
import img2 from '../../assets/bhut_shuddhi_3.jpg';
import img3 from '../../assets/jalaneti.jpg';
import img4 from '../../assets/pregnancy.jpg';
import img5 from '../../assets/shanmukhi_mudra.jpg';
import img6 from '../../assets/surya_kriya_2.jpg';
import img7 from '../../assets/surya_shakti.jpg';
import img8 from '../../assets/yogasanas.jpg';

const cardData = [
    {
        image: img1,
        title: "ANGAMARDANA",
        description: "a set of 30 dynamic practices designed to energize the body, boost physical fitness, and enhance mental well-being.",
        buttonText: "Know More",
        url: "angamardana",
        startDate: "2024-11-23",
        endDate: "2024-11-26"
    },
    {
        image: img2,
        title: "BHUTA SHUDDHI",
        description: "a transformative practice that purifies the body’s five elements, fostering harmony and balance.",
        buttonText: "Know More",
        url: "bhuta-shuddhi",
        startDate: "2025-03-15",
        endDate: "2025-03-16"
    },
    {
        image: img3,
        title: "JAL NETI",
        description: "a traditional yogic cleansing practice that improves breathing and enhance overall respiratory health.",
        buttonText: "Know More",
        url: "jal-neti",
        startDate: "2024-11-01",
        endDate: "2024-11-01"
    },
    {
        image: img4,
        title: "PREGNANCY PRACTICES",
        description: "supports pregnant women’s well-being and healthy pregnancies, inspired by ancient Indian wisdom.",
        buttonText: "Know More",
        url: 'pregnancy-practices',
        startDate: "2024-11-01",
        endDate: "2024-11-01"
    },
    {
        image: img5,
        title: "SHANMUKHI MUDRA",
        description: "revitalizes the face and eyes, promotes balance and enhances awareness for deeper meditation.",
        buttonText: "Know More",
        url: "shanmukhi-mudra",
        startDate: "2025-03-15",
        endDate: "2025-03-16"
    },
    {
        image: img6,
        title: "SURYA KRIYA",
        description: "a powerful 21-step yogic practice rooted in tradition, offering a holistic approach to health and well-being.",
        buttonText: "Know More",
        url: "surya-kriya",
        startDate: "2024-11-01",
        endDate: "2024-11-01"
    },
    {
        image: img7,
        title: "SURYA SHAKTI",
        description: "a dynamic yogic practice, featuring a powerful sequence of 18 Sun Salutation postures.",
        buttonText: "Know More",
        url: "surya-shakti",
        startDate: "2024-11-01",
        endDate: "2024-11-01"
    },
    {
        image: img8,
        title: "YOGASANAS",
        description: "powerful postures, stabilize the mind and emotions, elevate consciousness, balance energy and slow aging.",
        buttonText: "Know More",
        url: "yogasanas",
        startDate: "2024-11-01",
        endDate: "2024-11-01"
    },
  ];
  
  export default cardData;
  
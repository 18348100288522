import styles from './Dashboard.module.scss';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import React, { useRef, useState } from "react";

const Dashboard = () => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [reviewFees, setReviewFees] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const testFormData = new FormData(formRef.current);
    const convertData = new FormData(e.target);

    // Convert FormData to a plain object
    const data = {};
    convertData.forEach((value, key) => {
      data[key] = value;
    });
    data.reviewFees = reviewFees;
    console.log(JSON.stringify(data, null, 2));
    // console.log(testFormData)
    fetch(process.env.REACT_APP_GOOGLE_SCRIPT_URL, {
      method: 'POST',
      body: testFormData,
    }).then(res => res.json())
      .then(data => {
        console.log(data);
        formRef.current.reset();
        setSubmitted(true)
      })
      .catch(err => console.log(err));
  };

  const reviewCalculate = (e) => {
    let given_value = e.target.value;
    let calculate_review_fees = given_value*0.2;
    setReviewFees(Math.round(calculate_review_fees))
  }

  return (<>{ submitted ? <> <div className='d-flex justify-content-center p-5 m-5 dashboard_page'>
    <div className='text-center'>
        <h3>Program saved successfully!!!</h3>
    </div>
    </div> </> : <div className={`py-5`}>
    <Container className={styles.container}>
      <Form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="programName">
            <Form.Label>Name of the Program *</Form.Label>
            <Form.Select aria-label="Default select example" name="program" required>
                <option>please select</option>
                <option value="Angamardana">Angamardana</option>
                <option value="Bhutashuddhi">Bhutashuddhi</option>
                <option value="Jal Neti">Jal Neti</option>
                <option value="Pregnancy">Pregnancy</option>
                <option value="Shanmukhi">Shanmukhi</option>
                <option value="Surya Kriya">Surya Kriya</option>
                <option value="Surya Shakti">Surya Shakti</option>
                <option value="Yogasanas">Yogasanas</option>
            </Form.Select>
        </Form.Group>
        <div className='row'>
        <Form.Group className="col-4 mb-3" controlId="formCity">
            <Form.Label>Program City *</Form.Label>
            <Form.Select aria-label="Default select example" name="city" required>
                <option>please select</option>
                <option value="Pune">Pune</option>
                <option value="Solapur">Solapur</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="col-4 mb-3" controlId="formPincode">
            <Form.Label>Pincode*</Form.Label>
            <input class="form-control" type="text" name="pincode" required  ></input>
        </Form.Group>
        </div>
        <Form.Group className="mb-3" controlId="fomAddress" required>
            <Form.Label>Address *</Form.Label>
            <Form.Control as="textarea" rows={3} name="address" required />
        </Form.Group>
        <Form.Group className={`row mb-3`}>
          <div className='col-5'>
          <Form.Group className={`row mb-3`}>
            <Form.Label className='col'>From Time *</Form.Label>
            <Form.Select className='col' aria-label="Default select example" name="fromTime" required>
                <option>please select</option>
                <option value="5:00">5:00</option>
                <option value="5:30">5:30</option>
                <option value="6:00">6:00</option>
                <option value="6:0">6:30</option>
                <option value="7:00">7:00</option>
                <option value="7:30">7:30</option>
                <option value="8:00">8:00</option>
                <option value="8:30">8:30</option>
                <option value="9:00">9:00</option>
                <option value="9:30">9:30</option>
                <option value="10:00">10:00</option>
                <option value="10:30">10:30</option>
                <option value="11:00">11:00</option>
                <option value="11:30">11:30</option>
                <option value="12:00">12:00</option>
                <option value="12:30">12:30</option>
                <option value="1:00">1:00</option>
                <option value="1:30">1:30</option>
                <option value="2:00">2:00</option>
                <option value="2:30">2:30</option>
                <option value="3:00">3:00</option>
                <option value="3:30">3:30</option>
                <option value="4:00">4:00</option>
                <option value="4:30">4:30</option>                
            </Form.Select>
            <Form.Select className='col' aria-label="Default select example" name="fromTimeOfTheDay" required>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </Form.Select>
          </Form.Group>
          </div>
          <div className='col-1'></div>
        <div className='col-5'>
          <Form.Group className={`row mb-3`}>
            <Form.Label className='col'>To Time *</Form.Label>
            <Form.Select className='col' aria-label="Default select example" name="toTime" required>
                <option>please select</option>
                <option value="5:00">5:00</option>
                <option value="5:30">5:30</option>
                <option value="6:00">6:00</option>
                <option value="6:0">6:30</option>
                <option value="7:00">7:00</option>
                <option value="7:30">7:30</option>
                <option value="8:00">8:00</option>
                <option value="8:30">8:30</option>
                <option value="9:00">9:00</option>
                <option value="9:30">9:30</option>
                <option value="10:00">10:00</option>
                <option value="10:30">10:30</option>
                <option value="11:00">11:00</option>
                <option value="11:30">11:30</option>
                <option value="12:00">12:00</option>
                <option value="12:30">12:30</option>
                <option value="1:00">1:00</option>
                <option value="1:30">1:30</option>
                <option value="2:00">2:00</option>
                <option value="2:30">2:30</option>
                <option value="3:00">3:00</option>
                <option value="3:30">3:30</option>
                <option value="4:00">4:00</option>
                <option value="4:30">4:30</option>                
            </Form.Select>
            <Form.Select className='col' aria-label="Default select example" name="toTimeOfTheDay" required>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </Form.Select>
          </Form.Group>
        </div>
        </Form.Group>
        <Form.Group className={`row mb-3`}>
          <Form.Group className="col-4 mb-3" controlId="formFees">
            <Form.Label>Fees*</Form.Label>
            <input class="form-control" type="number" min={0} name="Fees" onChange={reviewCalculate} required></input>
          </Form.Group>
          <Form.Group className="col-4 mb-3" controlId="formReviewFees">
            <Form.Label>Review Fees(will be 20% of actual fees)</Form.Label>
            <input class="form-control" type="number" min={0} name="ReviewFees" value={reviewFees} disabled></input>
          </Form.Group>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>WhatsApp group link</Form.Label>
          <input className='form-control' type='text' name='whatsapplink'></input>
        </Form.Group>
        <Form.Group className="mb-3" controlId="Ifany">
            <Form.Label>Special notes for the Program
            </Form.Label>
            <Form.Control as="textarea" rows={2} name="specialNotes" />
        </Form.Group>
        <Button className={styles.button} disabled={loading ? true : false} variant="success" type="submit">
            Submit {loading ? <div class="spinner-border spinner-border-sm text-light" role="status">
  <span class="visually-hidden">Loading...</span>
</div> : ''}
        </Button>
      </Form>
    </Container>
    </div> }</>
  );
};
export default Dashboard;
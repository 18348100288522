import styles from './Shanmukhi.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/shanmukhi_mudra_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Shanmukhi = () => {
    return(
        <div className={styles.title}>
            <h1>Shanmukhi Mudra</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='shanmukhi' width='100%'></img>
                    <p>Shanmukhi Mudra is a gentle yet profound practice that revitalizes the face and eyes, fostering inner balance and enhancing awareness, leading to a deeper meditative state.</p>
                    <p><b>Benefits:</b><br/>
                    ♦ Improves vision.<br/>
                    ♦ Brightens the eyes and face. It improves the aura in the face. People concerned about their beauty can do it, actors can do it.<br/>
                    ♦ Helps turn a person inward, prepares one for pratyahara.<br/>
                    ♦ Rejuvenates different parts of the face, including helping with ailments related to nose ears, eyes.<br/>
                    ♦ Helps relieve vertigo and tinnitus.<br/>
                    ♦ Enhances awareness.<br/>
                    ♦ Brings about mental balance.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Shanmukhi Mudra <hr/></h5>
                        <p><b>Location: </b>Manorama Hall, Railway lines, Old Employment chowk, Solapur - 413002</p>
                        <p><b>Investment/Fees:</b> 1100 /-</p>
                        <p><b>Date:</b> 15th Mar 2025</p>
                        <p><b>Time:</b> 07:45 AM - 09:00 AM</p>
                        <Link to={"/shanmukhi/registration/a"}>
                            <Button>Register Now</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shanmukhi;